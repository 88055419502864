import React from "react"
import {Link, Outlet, useLocation, matchRoutes} from "react-router-dom"

import Box from "@mui/material/Box"
import AppBar from "@mui/material/AppBar"
import Toolbar from "@mui/material/Toolbar"

import Button from "@mui/material/Button"
import ButtonBase from "@mui/material/ButtonBase"

import logoLong from "../assets/img/ktourstory-logo-long.svg"
import Auth from "../partials/Auth"
import {Badge} from "@mui/material"
import UnreadCount from "../hooks/chat/unReadCount"
import {useAuth} from "../hooks/auth";

const superNavItem = [
    {
        key: "RESERVATION",
        name: "RESERVATION",
        path: "/reservation",
    },
    {
        key: "OPERATION",
        name: "OPERATION",
        path: "/operation",
    },
    {
        key: "STATICS",
        name: "STATICS",
        path: "/statics",
    },

    {
        key: "AGGREGATION",
        name: "AGGREGATION",
        path: "/aggregation",
    },
    {
        key: "MONITOR",
        name: "MONITOR",
        path: "/monitor",
    },
    {
        key: "CS",
        name: "CS",
        path: "/cs",
    },
    {
        key: "CHAT",
        name: "CHAT",
        path: "/chat/rooms",
    },
    {
        key: "BALANCE",
        name: "BALANCE",
        path: "/balance",
    },
    {
        key: "RECONCILIATION",
        name: "RECONCILIATION",
        path: "/reconciliation",
    },
    {
        key: "LOG",
        name: "LOG",
        path: "/log",
    },
    {
        key: "SETTINGS",
        name: "SETTINGS",
        path: "/settings",
    },
]

const basicNavItems = [
    {
        key: "RESERVATION",
        name: "RESERVATION",
        path: "/reservation",
    },
    {
        key: "OPERATION",
        name: "OPERATION",
        path: "/operation",
    },
    {
        key: "STATICS",
        name: "STATICS",
        path: "/statics",
    },

    {
        key: "AGGREGATION",
        name: "AGGREGATION",
        path: "/aggregation",
    },
    {
        key: "MONITOR",
        name: "MONITOR",
        path: "/monitor",
    },
    {
        key: "CS",
        name: "CS",
        path: "/cs",
    },
    {
        key: "CHAT",
        name: "CHAT",
        path: "/chat/rooms",
    },
    {
        key: "BALANCE",
        name: "BALANCE",
        path: "/balance",
    },
    {
        key: "RECONCILIATION",
        name: "RECONCILIATION",
        path: "/reconciliation",
    },
    {
        key: "SETTINGS",
        name: "SETTINGS",
        path: "/settings",
    },
]

const csNavItems = [
    {
        key: "RESERVATION",
        name: "RESERVATION",
        path: "/reservation",
    },
    {
        key: "OPERATION",
        name: "OPERATION",
        path: "/operation",
    },
    {
        key: "CHAT",
        name: "CHAT",
        path: "/chat/rooms",
    },
    {
        key: "BALANCE",
        name: "BALANCE",
        path: "/balance",
    }
]

const promotionsNavItems = [
    {
        key: "CHAT",
        name: "CHAT",
        path: "/chat/rooms",
    },
]

const balanceNavItems = [
    {
        key: "RECONCILIATION",
        name: "RECONCILIATION",
        path: "/",
    },
    {
        key: "BALANCE",
        name: "BALANCE",
        path: "/balance",
    },
    {
        key: "CHAT",
        name: "CHAT",
        path: "/chat/rooms",
    },
]

const emptyNavItems: any[] = [];

const DefaultLayout: React.FC = () => {
    const location = useLocation()
    const matches = matchRoutes(superNavItem, location)
    const match = matches?.[0]
    const {unReadCount} = UnreadCount()
    const {user, auth} = useAuth();
    const isSuper = auth?.level === 0;
    const isCs = auth?.level === 1.25;
    const isPromotion = user?.email?.includes('cs@ktourstory.com');
    const isBalance  = user?.email?.match(/ktsacc\d{1,2}@ktourstory\.com/);
    const navItems =
        user && auth ?
            isSuper ? superNavItem : isBalance ? balanceNavItems : isCs ? csNavItems : isPromotion ? promotionsNavItems : basicNavItems
            : emptyNavItems;

    return (
        <Box>
            <AppBar component="nav">
                <Toolbar>
                    <ButtonBase
                        component={Link}
                        to={"/"}
                        sx={{
                            height: "28px",
                        }}
                    >
                        <img src={logoLong} alt="K TOUR STORY"/>
                    </ButtonBase>

                    <Box
                        sx={{
                            flex: {xs: 1},
                            display: "flex",
                            alignItems: "center",
                            gap: "128px",
                            overflowY: "scroll",
                            marginLeft: "60px",
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                gap: "48px",
                            }}
                        >
                            {navItems.map(({key, name, path}) => (
                                <>
                                    <Badge
                                        badgeContent={key === "CHAT" ? unReadCount : ""}
                                        color={key === "CHAT" ? "error" : undefined}
                                        max={50}
                                        sx={{
                                            "& .MuiBadge-badge": {
                                                right: "2px",
                                                top: "10px",
                                            },
                                        }}
                                    >
                                        <Button
                                            color={"inherit"}
                                            key={key}
                                            component={key === "CHAT" ? "button" : Link}
                                            to={path}
                                            sx={(theme) => ({
                                                position: "relative",
                                                fontSize: "1.125rem",
                                                fontWeight: match?.pathname === path ? "bold" : "medium",
                                                color:
                                                    match?.pathname === path
                                                        ? theme.palette.text.primary
                                                        : theme.palette.text.disabled,
                                            })}
                                            onClick={() => {
                                                if (key === "CHAT") {
                                                    window.open(`/chat/rooms`, "_blank", "width=375,height=700")
                                                }
                                            }}
                                        >
                                            {name}
                                        </Button>
                                    </Badge>
                                </>
                            ))}
                            <Auth/>
                        </Box>
                    </Box>
                </Toolbar>
            </AppBar>
            <Box
                sx={(theme) => ({
                    minWidth: theme.breakpoints.values.xl,
                    py: 14,
                })}
            >
                <Outlet/>
            </Box>
        </Box>
    )
}

export default DefaultLayout
