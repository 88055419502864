import React, {useCallback, useMemo, useState} from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import {Product} from "../../../models/Product";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import {Operation} from "../../../models/Operation";
import {User} from "../../../models/User";
import {DataGridPro} from "@mui/x-data-grid-pro";

type CountRow = {
  id: string,
  hierarchy: string[]
  product: string,
  team?: string,
  fourSeat: number,
  twoSeat: number,
}


const BLOCK = "------------------------------"
const NOTICE =
  '\n' +
  "**라스트미닛/노쇼로 인한 추가/취소 부탁드립니다.\n" +
  "**취소는 레일바이크 출발 1시간 20분 전까지 필요 수량을 확정한 뒤 전화 취소 진행\n" +
  "**레일바이크 취소 전화번호: 033-245-1000 (1번 7번 누르면 바로 연결)\n" +
  "**최종발권 후 https://tinyurl.com/4bybr4ay 폼 작성 부탁드립니다.\n" +
  "------------------------------"

type RailbikeProps = {
  date: string,
  operation: Operation,
  products: Record<string, Product>,
  users: Record<string, User>,
  area: string,
};

const calcPrivateBikes = (people: number) => {
  let fourSeat = 0
  let twoSeat = 0
  if (people <= 2) {
    twoSeat += 1
  } else {
    fourSeat = people % 4 !== 0 ? Math.floor(people / 4) + 1 : people / 4
  }

  return {
    fourSeat,
    twoSeat,
  }
}

const calcShareBikes = (people: number) => {
  if (people === 1) {
    return 0.25
  } else if (people === 2) {
    return 0.5
  } else if (people === 3) {
    return 0.75
  } else {
    let bike = people / 4
    const remainder = people % 4
    if (remainder === 1 || remainder === 2) {
      bike += 0.5
    } else if (remainder === 3) {
      bike += 1
    }
    return bike
  }
}

const matchingProduct = (productName: string, products: Record<string, Product>): { id: string, product: string } => {
  const matchedProduct = Object.values(products).find(product => product.name === productName);
  return {id: matchedProduct?.id ?? productName, product: productName};
}

export default function Railbike(props: RailbikeProps) {
  const {operation, products} = props;
  const [railbikeProducts, setRailbikeProducts] = useState<{
    id: string,
    product: string
  }[]>(
    Object.values(products)
      .filter(p =>
        p.status === 'ON'
        && (p.option && !!p.option.find((o) => o.option.trim().toUpperCase() === 'PV BIKE'))
      )
      .map(p => ({id: p.id, product: p.name}))
  );

  const allProductOptions = useMemo(() => {
    return Object.values(products ?? {})
      .filter(p => p.status === 'ON')
      .map((p) => ({id: p.id, product: p.name}));
  }, [products]);

  const railbikeProductIds = useMemo(() => railbikeProducts.map((rp) => rp.id), [railbikeProducts]);
  const allTours = Object.values(operation.tours ?? {});
  const railbikeTours = allTours.filter(tour => railbikeProductIds.includes(tour.productId));

  const counts: Record<string, { teamName: string, fourSeat: number, twoSeat: number }[]> =
    railbikeTours.reduce((result, tour) => {
      const productId = tour.productId;
      const product = products[productId];
      if (!result[product.name]) result[product.name] = [];
      const teams = Object.values(tour.teams ?? {});
      teams.forEach((team, idx) => {
        const teamName = product.name + (idx + 1);
        const reservations = Object.values(team.reservations ?? {});
        const {fourSeat, twoSeat} = reservations.reduce((result, reservation) => {
          const isPV = reservation.option?.map(o => o.option).join(',').toUpperCase().includes('PV') ?? false;
          const people = reservation.adult + reservation.kid + reservation.infant;

          const privateBikeCounts = isPV ? calcPrivateBikes(people) : {fourSeat: 0, twoSeat: 0};
          const shareBikeCount = isPV ? 0 : calcShareBikes(people);
          result.fourSeat += (privateBikeCounts.fourSeat + shareBikeCount);
          result.twoSeat += privateBikeCounts.twoSeat;

          return result;
        }, {fourSeat: 0, twoSeat: 0});
        if(twoSeat === 0 && fourSeat === 0) return;
        result[product.name].push({teamName, twoSeat: Math.ceil(twoSeat), fourSeat: Math.ceil(fourSeat)});
      })
      return result;
    }, {} as Record<string, { teamName: string, fourSeat: number, twoSeat: number }[]>);

  const countRows = Object.entries(counts)
    .filter(([_, teams]) => teams.length > 0)
    .flatMap<CountRow>(([product, teams]) => {
      const teamRows: CountRow[] = teams.map(team => ({
        id: [product, team.teamName].join('-'),
        product,
        hierarchy: [product, team.teamName],
        team: team.teamName,
        fourSeat: team.fourSeat,
        twoSeat: team.twoSeat
      }));

      const totalCount = teams.reduce((result, team) => ({
        fourSeat: result.fourSeat + team.fourSeat,
        twoSeat: result.twoSeat + team.twoSeat
      }), {fourSeat: 0, twoSeat: 0});

      const tourRow: CountRow = {
        id: product,
        product,
        hierarchy: [product],
        fourSeat: totalCount.fourSeat,
        twoSeat: totalCount.twoSeat
      };
      return [tourRow, ...teamRows];
    })

  // const getSeater = () => {
  //   const bikes = new Map<string, { bike: number; pv: number }[]>(railbikeProducts.map((product) => [product, []]))
  //   rails.forEach((values, key) => {
  //     let pvCount = 0
  //     let bikeCount = 0
  //     values.forEach((value: any) => {
  //       const { people, pv } = value
  //       if (pv === "PV") {
  //         const { bike, pv } = calcPrivateBikes(people)
  //         pvCount += pv
  //         bikeCount += bike
  //       } else {
  //         bikeCount += calcShareBikes(people)
  //         if (people > 2) {
  //           if (bikeCount === 0.25 || bikeCount === 0.75) {
  //             bikeCount += 0.25
  //           }
  //         }
  //       }
  //     })
  //
  //     if (bikeCount !== 0) {
  //       bikeCount = Math.ceil(bikeCount)
  //     }
  //
  //     const product = key.replace(/\d/g, " ")
  //     bikes.set(product, [...(bikes.get(product) || []), { bike: bikeCount, pv: pvCount }])
  //   })
  //
  //   return bikes
  // }

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Autocomplete
            multiple
            options={allProductOptions}
            value={railbikeProducts}
            getOptionLabel={(productOption) => productOption.product}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={(_, value) => {
              setRailbikeProducts(value);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                error={railbikeProducts.length === 0}
                label={'Railbike Product'}
                InputLabelProps={{shrink: true}}
              />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <Card>
            <CardContent>
              <Box
                sx={{
                  height: '70vh'
                }}
              >
                <DataGridPro
                  treeData
                  defaultGroupingExpansionDepth={1}
                  getTreeDataPath={useCallback((v: CountRow) => v.hierarchy, [])}
                  columns={[
                    {field: 'twoSeat', headerName: '2인', align: 'center', headerAlign: 'center'},
                    {field: 'fourSeat', headerName: '4인', align: 'center', headerAlign: 'center'},
                  ]}
                  rows={countRows}
                />
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={8}>
          <Card>
            <CardContent>
              <TextField
                fullWidth
                multiline
                sx={{
                  height: '100%',
                }}
                value={
                  Object.entries(counts)
                    .filter(([_, teams])=> teams.length > 0)
                    .map(([product, teams]) => {
                      const headerPhrase = `[${product.trim()}]\n**레일바이크 시 분**\n예약번호:\n`;
                      let teamPhrase = `예상 필요대수\n`;
                      let totalFourSeat = 0;
                      let totalTwoSeat = 0;
                      teams.forEach(({teamName, fourSeat, twoSeat}) => {
                        teamPhrase += `${teamName} : 2인승 ${twoSeat}대 / 4인승 ${fourSeat}대\n`;
                        totalFourSeat += fourSeat;
                        totalTwoSeat += twoSeat;
                      });
                      const totalPhrase = `\n총 구매수량: 2인승 대 / 4인승 대\n총 필요수량: 2인승 ${totalTwoSeat}대 / 4인승 ${totalFourSeat}대\n\n`;

                      return headerPhrase + totalPhrase + teamPhrase + BLOCK;
                    })
                    .join('\n')
                    .concat(NOTICE)
                }
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  )
}
