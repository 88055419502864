import React, {useState} from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";

import {DatePicker} from "@mui/x-date-pickers";

import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';

import {Product, ProductOption} from '../models/Product';
import {Pickup} from "../models/Pickup";
import {Agency} from "../models/Agency";
import {Nationality} from "../models/Nationality";
import {ReservationBase, ReservationOption, ReservationProp, ReservationPropType} from "../models/Reservation";

import {useRead} from "../hooks/realtime";

import dayjs from "dayjs";
import {callFunction, logAction, queryFireStore} from "../hooks/firebase";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";


type TempReservation = {

  agency: string,
  agencyCode: string,
  product: string,
  productId: string,
  date: string,
  pickupPlace: string,
  pickupTime: string,
  clientName: string,
  adult: number,
  kid: number,
  infant: number,
  option: ReservationOption[],
  nationality: string,
  language: string,
  tel: string,
  messenger: string,
  email: string,
  memo: string,
  stroller?: boolean,
}


export default function (props: { defaultReservation: TempReservation | null, onClose: (update?: boolean) => void }) {
  const {onClose, defaultReservation} = props;
  const [loading, setLoading] = useState(false);
  const {data: agencies} = useRead<{ [agencyId: string]: Agency }>('/agency');
  const {data: nationalities} = useRead<{ [nationId: string]: Nationality }>('/nationality');
  const {data: products} = useRead<{ [productId: string]: Product }>('/product');
  const {data: pickups} = useRead<{ [area: string]: { [pickupId: string]: Pickup } }>(`/pickup`);
  const [tempReservation, setTempReservation] = useState<TempReservation>(
    {
      agency: 'KT',
      agencyCode: '',
      product: defaultReservation?.product ?? '',
      productId: defaultReservation?.productId ?? '',
      date: '',
      pickupPlace: defaultReservation?.pickupPlace ?? '',
      pickupTime: defaultReservation?.pickupTime ?? '',
      clientName: defaultReservation?.clientName ?? '',
      adult: defaultReservation?.adult ?? 1,
      kid: defaultReservation?.kid ?? 0,
      infant: defaultReservation?.infant ?? 0,
      option: defaultReservation?.option ? JSON.parse(JSON.stringify(defaultReservation.option)) : [],
      nationality: defaultReservation?.nationality ?? '',
      language: defaultReservation?.language ?? 'English',
      tel: defaultReservation?.tel ?? '',
      messenger: defaultReservation?.messenger ?? '',
      email: defaultReservation?.email ?? '',
      memo: defaultReservation?.memo ?? '',
      stroller: defaultReservation?.stroller ?? false,
    }
  );

  const product = (products && tempReservation) ? products[tempReservation?.productId as string] : null

  const productArea = product?.area ?? '';

  const agencyOptions = Object.entries(agencies ?? {}).map(([agencyId, agency]) => ({
    id: agency.code,
    name: agency.name
  }));

  const pickupOptions = Object.entries(pickups?.[productArea.toLowerCase()] ?? {}).sort(([_, a], [__, b]) => (a.order ?? 99) - (b.order ?? 99)).map(([pickupId, pickup]) => ({
    id: pickupId,
    name: pickup.place
  }));

  const nationalityOptions = Object.entries(nationalities ?? {}).map(([nationId, nationality]) => ({
    id: nationality.place,
    name: `${nationality.place}`
  }));

  const productOptions = Object.values(products ?? {}).filter((p) => {
    const date = new Date(tempReservation.date);
    const day = date.getDay() === 0 ? 6 : date.getDay() - 1;
    // p.availableDays[day];
    return p.status === 'ON'
  }).map((product) => ({id: product.id, name: product.name}));

  const options = (product?.option ?? []).filter((option) => {
    // return !(reservation?.option.map((o) => o.option).concat(['Ignore']).includes(option.option));
    return !['Ignore'].includes(option.option);
  }).map((option) => ({
    option: option.option,
    people: tempReservation?.option.find((o) => o.option === option.option)?.people ?? 0
  }));


  const handleSave = () => {
    if (!tempReservation.date) return alert('날짜 정보를 입력해주세요.');
    if (!tempReservation.product) return alert('상품 정보를 입력해주세요.');
    if (!tempReservation.pickupPlace) return alert('픽업 정보를 입력해주세요.');
    if (
      ((!tempReservation.adult && tempReservation.adult !== 0) || Number.isNaN(tempReservation.adult) || tempReservation.adult < 0)
      || ((!tempReservation.kid && tempReservation.kid !== 0) || Number.isNaN(tempReservation.kid) || tempReservation.kid < 0)
      || ((!tempReservation.infant && tempReservation.infant !== 0) || Number.isNaN(tempReservation.infant) || tempReservation.infant < 0)
      || (tempReservation.adult + tempReservation.kid + tempReservation.infant) <= 0
    ) return alert('구성원 정보를 입력해주세요.');
    setLoading(true);
    (async () => {
      if (tempReservation.agencyCode) {
        const d = await queryFireStore('reservation', [['agencyCode', '==', tempReservation.agencyCode]])
        if (d.length > 0) {
          window.alert('이미 있는 예약번호입니다.')
          return;
        }
      }

      const s = await callFunction<{ result: any }>('createReservation', {reservation: tempReservation})
      if (s.result.error) {
        return alert(`예약 생성에 실패했습니다. ${s.result.error}`);
      }
      if (s.result) {
        logAction('RESERVATION', 'CREATE RESERVATION', s.result.id, `Create new reservation: ${s.result.id}(${s.result.date}, ${s.result.agency}, ${s.result.agencyCode})`, s.result, {reservation: JSON.parse(JSON.stringify(s.result))});
        return onClose(true);
      }
    })()
      .catch((e) => {
        alert(`예약 생성에 실패했습니다. ${e}`);
      })
      .finally(() => {
        setLoading(false);
      })
  }

  const handleClose = () => {
    onClose();
  }

  return (
    <Modal
      open
      onClose={() => {
        if (loading) {
          return;
        }
        handleClose()
      }}
    >

      {
        loading
          ?
          <Box
            color={'white'}
            height={'100vh'}
            width={'100vw'}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <CircularProgress color={'inherit'}/>
          </Box>
          :
          <Box
            sx={(theme) => ({
              width: '80vw',
              maxHeight: '80vh',
              position: 'relative' as 'relative',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            })}
          >

            <Box sx={{
              position: 'fixed',
              display: 'inline-flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              bottom: 0,
              left: '50%',
              transform: 'translate(-50%, 125%)',
              zIndex: 999,
              backgroundColor: 'white',
              borderRadius: 5,
              boxShadow: 2,
              py: 1,
              px: 2
            }}>
              <IconButton onClick={handleSave}>
                <EditIcon/>
              </IconButton>
              <IconButton onClick={handleClose}>
                <CloseIcon/>
              </IconButton>
            </Box>


            <Paper
              sx={(theme) => ({
                padding: '32px 24px',
              })}

            >

              <Grid
                container
                spacing={2}
              >
                <Grid
                  item
                  sx={{
                    position: 'relative',
                  }}
                >

                  <Box
                    sx={{
                      position: 'relative',
                      maxHeight: '75vh',
                      overflowY: 'auto',
                    }}
                  >

                    <Box
                      sx={(theme) => ({
                        borderRadius: 3,
                        backgroundColor: theme.palette.background.default,
                        p: 4
                      })}
                    >
                      <Grid
                        container
                        spacing={4}
                        component={'form'}
                      >
                        <Grid
                          item
                          xs={2}
                        >
                          <Typography
                            fontWeight={'bold'}>
                            Tour
                          </Typography>
                        </Grid>

                        <Grid
                          item
                          xs={10}
                        >
                          <Autocomplete
                            options={productOptions}
                            getOptionLabel={(option) => option.name}
                            value={{
                              id: (tempReservation?.productId ?? '') as string,
                              name: (tempReservation?.product ?? '') as string
                            }}
                            onChange={(_, productOption) => {
                              if (productOption) {
                                setTempReservation({
                                  ...tempReservation,
                                  productId: productOption.id,
                                  product: productOption.name,
                                });
                              }
                            }}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            sx={{backgroundColor: 'white'}}
                            renderInput={
                              (params) => (
                                <TextField
                                  {...params}
                                  error={!tempReservation?.productId || !tempReservation?.product}
                                  fullWidth
                                  label={'Product'}
                                  InputLabelProps={{shrink: true}}
                                />
                              )
                            }
                          />
                        </Grid>
                        <Grid
                          item
                          xs={2}
                        >
                        </Grid>

                        <Grid
                          item
                          xs={5}
                        >

                          <DatePicker
                            disablePast
                            format={'YYYY-MM-DD'}
                            value={dayjs(tempReservation?.date)}
                            onChange={(date) => {
                              if (date) {
                                setTempReservation({
                                  ...tempReservation,
                                  date: dayjs(date).format('YYYY-MM-DD')
                                })
                              }
                            }}
                            slotProps={{
                              // inputAdornment: {
                              //     sx:{display:'none'}
                              // },
                              textField: {
                                fullWidth: true,
                                sx: {backgroundColor: 'white'},
                                label: 'Date',
                              }
                            }}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={5}
                        >
                          <Autocomplete
                            options={pickupOptions}
                            getOptionLabel={(option) => option.name}
                            value={{
                              id: tempReservation?.pickupPlace ?? '',
                              name: tempReservation?.pickupPlace ?? ''
                            }}
                            onChange={(_, pickupOption) => {
                              if (pickupOption) {
                                setTempReservation({
                                  ...tempReservation,
                                  pickupPlace: pickupOption.id,
                                });
                              }
                            }}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            sx={{backgroundColor: 'white'}}
                            renderInput={
                              (params) => (
                                <TextField
                                  {...params}
                                  error={!tempReservation.pickupPlace}
                                  fullWidth
                                  label={'Pick up'}
                                  InputLabelProps={{shrink: true}}
                                />
                              )
                            }
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                        >
                          <Divider/>
                        </Grid>
                        <Grid
                          item
                          xs={2}
                        >
                          <Typography
                            fontWeight={'bold'}
                          >
                            Agency
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={5}
                        >

                          <Autocomplete
                            options={agencyOptions}
                            getOptionLabel={(option) => `${option.id}(${option.name})`}
                            value={{
                              id: tempReservation?.agency ?? '',
                              name: agencyOptions.find(({id}) => id === tempReservation.agency)?.name ?? tempReservation?.agency ?? ''
                            }}
                            onChange={(_, agencyOption) => {
                              if (agencyOption) {
                                const updates = {
                                  ...tempReservation,
                                  agency: agencyOption.id,
                                };
                                switch (agencyOption?.id.toUpperCase() ?? ''){
                                  case 'AY': {
                                    updates['nationality'] = 'TAIWAN';
                                    updates['language'] = 'Chinese';
                                    break;
                                  }
                                  case 'MRT':{
                                    updates['nationality'] ='KOREA, REPUBLIC OF';
                                    updates['language'] = 'Korean';
                                    break;
                                  }
                                  case 'CP':{
                                    updates['nationality'] ='KOREA, REPUBLIC OF';
                                    updates['language'] = 'Korean';
                                    break;
                                  }
                                  case 'WG':{
                                    updates['nationality'] ='KOREA, REPUBLIC OF';
                                    updates['language'] = 'Korean';
                                    break;
                                  }
                                  case 'SS':{
                                    updates['nationality'] ='KOREA, REPUBLIC OF';
                                    updates['language'] = 'Korean';
                                    break;
                                  }
                                  case 'VE':{
                                    updates['nationality'] ='Japan';
                                    updates['language'] = 'English';
                                    break;
                                  }
                                  case 'KTTS':{
                                    updates['nationality'] ='KOREA, REPUBLIC OF';
                                    updates['language'] = 'English';
                                    updates['clientName'] = 'TEST';
                                    updates['pickupPlace'] = 'Myungdong';
                                    break;
                                  }
                                }
                                setTempReservation(updates);
                              }
                            }}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            sx={{backgroundColor: 'white'}}
                            renderInput={
                              (params) => (
                                <TextField
                                  {...params}
                                  fullWidth
                                  label={'Agency'}
                                  InputLabelProps={{shrink: true}}
                                />
                              )
                            }
                          />
                        </Grid>
                        <Grid
                          item
                          xs={5}
                        >
                          <TextField
                            fullWidth
                            label={'Agency Code'}
                            value={tempReservation?.agencyCode}
                            onChange={(event) => {
                              setTempReservation({
                                ...tempReservation,
                                agencyCode: (event.target.value ?? "").trim(),
                              });
                            }}
                            InputLabelProps={{shrink: true}}
                            sx={{backgroundColor: 'white'}}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                        >
                          <Divider/>
                        </Grid>
                        <Grid
                          item
                          xs={2}
                        >
                          <Typography
                            fontWeight={'bold'}>
                            Client
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={10}
                        >
                          <TextField
                            fullWidth
                            label={'Client Name'}
                            value={tempReservation?.clientName}
                            onChange={(event) => {
                              setTempReservation({
                                ...tempReservation,
                                clientName: event.target.value,
                              });
                            }}
                            InputLabelProps={{shrink: true}}
                            sx={{backgroundColor: 'white'}}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={2}
                        >
                        </Grid>
                        <Grid
                          item
                          xs={4}
                        >
                          <TextField
                            fullWidth
                            label={'Adult'}
                            type={'number'}
                            value={tempReservation?.adult}
                            onChange={(event) => {
                              const number = Number.parseInt(event.target.value);
                              const adult = number;
                              setTempReservation({
                                ...tempReservation,
                                adult: adult,
                              });
                            }}
                            InputLabelProps={{shrink: true}}
                            sx={{backgroundColor: 'white'}}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={3}
                        >
                          <TextField
                            fullWidth
                            label={'Kid'}
                            type={'number'}
                            value={tempReservation?.kid}
                            onChange={(event) => {
                              const number = Number.parseInt(event.target.value);
                              const kid = number;
                              setTempReservation({
                                ...tempReservation,
                                kid: kid,
                              });
                            }}
                            InputLabelProps={{shrink: true}}
                            sx={{backgroundColor: 'white'}}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={3}
                        >
                          <TextField
                            fullWidth
                            label={'Infant'}
                            type={'number'}
                            value={tempReservation?.infant}
                            onChange={(event) => {
                              const number = Number.parseInt(event.target.value);
                              const infant = number;
                              setTempReservation({
                                ...tempReservation,
                                infant: infant,
                              });
                            }}
                            InputLabelProps={{shrink: true}}
                            sx={{backgroundColor: 'white'}}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={2}
                        >
                        </Grid>
                        <Grid
                          item
                          xs={4}
                        >
                          <TextField
                            fullWidth
                            label={'Client Email'}
                            value={tempReservation?.email}
                            onChange={(event) => {
                              setTempReservation({
                                ...tempReservation,
                                email: event.target.value,
                              });
                            }}
                            InputLabelProps={{shrink: true}}
                            sx={{backgroundColor: 'white'}}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={3}
                        >
                          <TextField
                            fullWidth
                            label={'Client Tel'}
                            value={tempReservation?.tel}
                            onChange={(event) => {
                              setTempReservation({
                                ...tempReservation,
                                tel: event.target.value,
                              });
                            }}
                            InputLabelProps={{shrink: true}}
                            sx={{backgroundColor: 'white'}}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={3}
                        >
                          <TextField
                            fullWidth
                            label={'Client Messenger'}
                            value={tempReservation?.messenger}
                            onChange={(event) => {
                              setTempReservation({
                                ...tempReservation,
                                messenger: event.target.value,
                              });
                            }}
                            InputLabelProps={{shrink: true}}
                            sx={{backgroundColor: 'white'}}
                          />
                        </Grid>

                        <Grid
                          item
                          xs={2}
                        >
                        </Grid>

                        <Grid
                          item
                          xs={5}
                        >

                          <Autocomplete
                            options={nationalityOptions}
                            getOptionLabel={(option) => `${option.name}`}
                            value={{
                              id: tempReservation?.nationality ?? '',
                              name: tempReservation?.nationality ?? ''
                            }}
                            onChange={(_, nationalityOption) => {
                              if (nationalityOption) {
                                setTempReservation({
                                  ...tempReservation,
                                  nationality: nationalityOption.id,
                                });
                              }
                            }}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            sx={{backgroundColor: 'white'}}
                            renderInput={
                              (params) => (
                                <TextField
                                  {...params}
                                  fullWidth
                                  label={'Nationality'}
                                  InputLabelProps={{shrink: true}}
                                />
                              )
                            }
                          />
                        </Grid>
                        <Grid
                          item
                          xs={5}
                        >
                          <Autocomplete
                            multiple
                            options={[
                              {id: 'English', name: 'English'},
                              {id: 'Chinese', name: 'Chinese'},
                              {id: 'Japanese', name: 'Japanese'},
                              {id: 'Korean', name: 'Korean'}]
                            }
                            value={
                              (tempReservation?.language).split(',').map(s => s.trim()).filter(s => !!s)
                                .map((language) => ({
                                  id: language, name: language
                                }))
                            }
                            onChange={(_, languageOption) => {
                              if (languageOption.filter(o => !!o?.id).length) {
                                setTempReservation({
                                  ...tempReservation,
                                  language: languageOption.map((o) => o.id).join(', '),
                                });
                              } else {
                                setTempReservation({
                                  ...tempReservation,
                                  language: "",
                                });
                              }
                            }}
                            isOptionEqualToValue={(option: { id: string, name: string }, value: {
                              id: string,
                              name: string
                            }) => option.id.toUpperCase() === value.id.toUpperCase()}
                            getOptionLabel={(option: { id: string, name: string }) => `${option.id}`}
                            sx={{backgroundColor: 'white'}}
                            renderInput={
                              (params) => (
                                <TextField
                                  {...params}
                                  fullWidth
                                  label={'Language'}
                                  InputLabelProps={{shrink: true}}
                                />
                              )
                            }
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={2}
                        >
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={5}
                        >
                          <FormControlLabel
                            control={<Checkbox checked={tempReservation?.stroller ?? false}
                                               onChange={
                                                 (_, checked) => {
                                                   setTempReservation({
                                                     ...tempReservation,
                                                     stroller: checked
                                                   });
                                                 }}/>} label="Stroller/Wheelchair"/>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                        >
                          <Divider/>
                        </Grid>
                        <Grid
                          item
                          xs={2}
                        >
                          <Typography
                            fontWeight={'bold'}>
                            Option
                          </Typography>
                        </Grid>

                        {
                          options.map((option, idx) => (
                            <>
                              {
                                idx > 0
                                  ? (
                                    <Grid
                                      item
                                      xs={12}
                                      md={2}
                                    >
                                    </Grid>
                                  )
                                  : null
                              }
                              <Grid
                                item
                                xs={8}
                                key={option.option}
                              >
                                <TextField
                                  fullWidth
                                  disabled
                                  label={'Option'}
                                  value={option.option}
                                  InputLabelProps={{shrink: true}}
                                  sx={{backgroundColor: 'white'}}
                                />
                              </Grid>
                              <Grid
                                item
                                xs={2}
                                key={option.option + option.people}
                              >
                                <TextField
                                  fullWidth
                                  type={'number'}
                                  label={'People'}
                                  value={option.people}
                                  InputLabelProps={{shrink: true}}
                                  sx={{backgroundColor: 'white'}}
                                  onChange={(e) => {
                                    const value = Number.parseInt(e.target.value ? e.target.value : '0');
                                    if (Number.isNaN(value)) return;
                                    const reservationOptions = tempReservation?.option ?? []
                                    const scope = reservationOptions.find((o) => {
                                      return o.option === option.option
                                    });
                                    if (scope) {
                                      scope.people = value;
                                    } else {
                                      reservationOptions.push({
                                        option: option.option,
                                        people: value
                                      });
                                    }
                                    const filtered = reservationOptions.filter((o) => o.people)
                                    setTempReservation({
                                      ...tempReservation,
                                      option: filtered
                                    })
                                  }}
                                />
                              </Grid>
                            </>
                          ))
                        }
                        <Grid
                          item
                          xs={12}
                        >
                          <Divider/>
                        </Grid>
                        <Grid
                          item
                          xs={2}
                        >
                          <Typography
                            fontWeight={'bold'}>
                            Memo
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={10}
                        >
                          <TextField
                            fullWidth
                            multiline
                            minRows={5}
                            label={'Memo'}
                            value={tempReservation?.memo}
                            InputLabelProps={{shrink: true}}
                            onChange={(event) => {
                              setTempReservation({
                                ...tempReservation,
                                memo: event.target.value,
                              });
                            }}
                            sx={{backgroundColor: 'white'}}
                          />
                        </Grid>

                      </Grid>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Paper>
          </Box>
      }
    </Modal>
  )
}
